<template>
  <label
    :class="{
      'osk-field': true,
      'osk-checkbox': true,
      'osk-checkbox_withContent': slots.default,
    }"
  >
    <input
      :value="valueComp"
      type="checkbox"
      :disabled="disabled ?? false"
      class="osk-checkbox__input"
      @change="handleChange(value ?? true)"
    >

    <div
      v-if="slots.default"
      class="osk-checkbox__content"
    >
      <slot />
    </div>

    <template v-if="round">
      <UikitIcon
        v-if="checked"
        name="MultiChoiseON"
        class="osk-radio-card__icon"
        size="l"
      />

      <UikitIcon
        v-else
        name="MultiChoiseOFF"
        class="osk-radio-card__icon"
        size="l"
        color="grey-2"
      />
    </template>

    <template v-else>
      <UikitIcon
        v-if="checked"
        name="CheckOn"
        class="osk-checkbox__icon"
        :color="color"
      />

      <UikitIcon
        v-else
        name="CheckOff"
        class="osk-checkbox__icon"
        :color="color"
      />
    </template>

    <span
      v-if="label"
      class="osk-checkbox__label"
    >
      {{ label }}
    </span>

    <div
      v-if="slots.label"
      class="osk-checkbox__label"
    >
      <slot name="label" />
    </div>
  </label>
</template>

<script setup lang="ts">
import { RuleExpression, useField } from 'vee-validate';

import type { TColors } from '~/types/components/colors';

const props = defineProps<{
  modelValue?: any,
  name: string,
  value?: any // string | number,
  uncheckedValue?: string,
  label?: string,
  color?: TColors
  disabled?: boolean,
  required?: boolean,
  round?: boolean
  rules?: RuleExpression<string | number | number[] | boolean | null>,
}>();

const rulesComp = computed(() => {
  if (!props.required) return props.rules;

  if (!props.rules || (typeof props.rules === 'string' && !props.rules.includes('required'))) {
    return `required|${props.rules ?? ''}`;
  }

  return props.rules;
});

const { value: valueComp, checked, handleChange } = useField<any>(toRef(props, 'name'), rulesComp, {
  type: 'checkbox',
  initialValue: props.modelValue ?? undefined,
  checkedValue: props.value ?? true,
  uncheckedValue: props.uncheckedValue ?? false,
  syncVModel: true,
});

const slots = useSlots();
</script>

<style scoped lang="scss">
@import "~/assets/scss/settings/index";

.osk-checkbox {
  display: flex;
  flex-direction: row;

  &_withContent {
    align-items: center;
    justify-content: space-between;
  }

  &__input {
    display: none;
  }

  &__icon {
    cursor: pointer;
    flex-shrink: 0;
  }

  &__label {
    margin-left: $indent-compact;
    @include font-style($font-size-caption, $line-height-caption, $font-weight-medium);
    transform: translateY(2px);
  }

  &__content {
    display: flex;
    align-items: center;
  }
}
</style>
